import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyA8kajme0XJ32Vet_71EJ13S6vCGR0PUcY",
  authDomain: "sav-traveler-portal.firebaseapp.com",
  databaseURL: "https://sav-traveler-portal.firebaseio.com",
  projectId: "sav-traveler-portal",
  storageBucket: "sav-traveler-portal.appspot.com",
  messagingSenderId: "134221011062",
  appId: "1:134221011062:web:095b1b414c8e8c6e48e757"
};

!firebase.apps.length ? firebase.initializeApp(config) : '';

export default firebase;